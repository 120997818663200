<template>
  <div class="appeals-form appeals-form--search" v-if="searchPage">
    <pre class="notify notify--green success-notifications" v-if="notify" v-html="notify"></pre>
    <router-link :to="{ name: 'appeals-form' }">
      <span>Вернуться назад</span>
    </router-link>
    <form class="appeals-form" @submit.prevent="submitSearch">
      <div class="form-row form-row--1">
        <div class="search-form">
          <span class="search-form__title">Поиск обращения по ID</span>
          <div class="search-form__row">
            <InputComponent v-model.trim="searchQuery" placeholder="ID">
              <template v-slot:action>
                <IconComponent name="search" />
              </template>
            </InputComponent>
            <button style="height: 50px" class="btn btn--md btn--main">{{ searchButtonState }}</button>
          </div>
        </div>
      </div>
    </form>
    <div class="appeals-results">
      <template v-if="result && result.id">
        <span class="appeals-results__title">Результаты поиска обращения</span>
        <div class="appeals-results__item">
          <div class="appeals-results__item-header">
            <div class="appeals-results__item-header-left">
              <div class="appeals-results__item-top">
                <b>№ {{ result.uuid }}</b>
                <span>от {{ result.created_at | humanDate }}</span>
              </div>
              <span class="appeals-results__item-title">
                {{ result.surname }} {{ result.name }} {{ result.patronymic }}
              </span>
            </div>
            <div class="appeals-results__item-status" v-if="result.status">{{ result.status.name }}</div>
          </div>
          <div class="appeals-results__item-description">{{ result.description }}</div>
          <div class="appeals-results__item-response" v-if="result.department_comment">
            <span class="appeals-results__item-response-title">Ответ Правительства</span>
            <div class="appeals-results__item-response-description">{{ result.department_comment }}</div>
          </div>
        </div>
      </template>
      <span
        class="appeals-results__title"
        v-else-if="searchQuery && searchQuery.length && !result && searchSubmitted"
      >
        Обращения с таким идентификатором не существует
      </span>
    </div>
  </div>
  <form class="appeals-form" @submit.prevent="appealSubmit" v-else-if="mounted">
    <div class="form-row form-row--1">
      <div class="notify notify--green">
        Если Вы уже отправляли обращение и у вас есть идентификатор, Вы можете проверить статус,
        <router-link :to="{ name: 'appeals-form', hash: '#search' }">на этой форме</router-link>
      </div>
    </div>
    <div class="form-row form-row--1">
      <SelectComponent
        v-model="form.department.value"
        :errors="form.department.errors"
        :options="departments"
        label-name="title"
        title="Адресат"
        placeholder=""
        :clearable="false"
        disabled
      />
    </div>
    <div class="form-row form-row--1">
      <SelectComponent
        v-model="form.type.value"
        :errors="form.type.errors"
        :options="types"
        label-name="name"
        title="Тип обращения"
        placeholder=""
        :clearable="false"
      />
    </div>
    <div class="form-row form-row--3">
      <InputComponent
        v-model.trim="form.surname.value"
        :errors="form.surname.errors"
        title="Фамилия"
        placeholder=""
        required
        id="surname"
      />
      <InputComponent
        id="name"
        v-model.trim="form.name.value"
        :errors="form.name.errors"
        title="Имя"
        placeholder=""
        required
      />
      <InputComponent
        v-model.trim="form.patronymic.value"
        :errors="form.patronymic.errors"
        title="Отчество"
        placeholder=""
        id="patronymic"
      />
    </div>
    <div class="form-row form-row--2">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        title="Электронная почта"
        placeholder=""
        type="email"
        id="email"
        required
      />
      <InputComponent
        v-model.trim="form.phone.value"
        :errors="form.phone.errors"
        title="Телефон"
        placeholder="+7 (___) ___-__-__"
        mask="+7 (###) ###-##-##"
        id="phone"
      />
    </div>
    <div class="form-row form-row--1">
      <TextareaComponent
        v-model="form.description.value"
        title="Текст обращения"
        placeholder=""
        :errors="form.description.errors"
        required
        id="description"
      />
    </div>
    <div class="form-row form-row--3">
      <FileInputComponent
        :messages="form.file.messages"
        :errors="form.file.errors"
        v-model="file"
        title="Файл"
      />
    </div>
    <div class="form-row form-row--1">
      <div class="appeals-form__footer">
        <button type="submit" id="form-submit" class="btn btn--md btn--main">{{ appealButtonState }}</button>
        <div v-if="department && department.privacy">
          Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с
          <a target="_blank" :href="$store.state._env.MEDIA_ENDPOINT + department.privacy.path">
            политикой конфиденциальности
          </a>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import FileInputComponent from "components/inputs/FileInputComponent.vue";
import APPEAL_CREATE from "gql/mutations/AppealCreate.graphql";
import FILES_UPLOAD from "gql/mutations/FilesUpload.graphql";
import APPEALS_ITEM from "gql/queries/appeals_item.graphql";
import IconComponent from "components/IconComponent.vue";
import CaptchaModal from "components/modals/components/CaptchaModal.vue";

export default {
  name: "AppealsForm",
  data() {
    // let department = this.$store.state.department;
    return {
      /**
       * 0 - нет загрузки
       * 1 - идет загрузка файла
       * 2 - идет отправка формы
       */
      mounted: false,
      searchPage: false,
      searchSubmitted: false,
      searchQuery: null,
      loadingState: 0,
      result: null,
      departments: [
        {
          id: 0,
          // title: department?.title,
          title: "Правительство РД",
        },
      ],
      notify: null,
      file: null,
      form: {
        captcha: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        captcha_key: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        name: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        surname: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        patronymic: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        email: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        phone: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        file: {
          value: null,
          defaultValue: null,
          errors: [],
          messages: [
            "Поддерживаемые форматы: pdf, docx.\n" +
              "Размер: Не более 1 Мб.\n" +
              "Количество: Не более 1 файла.",
          ],
        },
        description: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        type: {
          value: {},
          defaultValue: null,
          errors: [],
        },
        department: {
          value: {
            id: 0,
            // title: department?.title,
            title: "Правительство РД",
          },
          defaultValue: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    searchButtonState() {
      if (this.loadingState === 1) {
        return "Загрузка..";
      }
      if (this.loadingState === 2) {
        return "Загрузка..";
      }
      return "Поиск";
    },
    appealButtonState() {
      if (this.loadingState === 1) {
        return "Загрузка файла..";
      }
      if (this.loadingState === 2) {
        return "Отправка письма...";
      }
      return "Отправить письмо";
    },
    department() {
      return this.$store.state.department;
    },
    types() {
      return this.$store.state.types;
    },
  },
  mounted() {
    this.searchPage = this.$route.hash === "#search";
    this.form.type.value = this.types[0];
    this.mounted = true;
  },
  watch: {
    searchQuery() {
      this.searchSubmitted = false;
    },
    "form.captcha.errors"(val) {
      if (val && val.length) {
        this.showCaptchaModal();
      }
    },
  },
  methods: {
    submitSearch() {
      if (this.loadingState === 0) {
        this.loadingState = 1;
        this.$apollo
          .query({
            query: APPEALS_ITEM,
            variables: {
              uuid: this.searchQuery,
            },
          })
          .then(({ data }) => {
            if (data && data.appeals_item) {
              this.result = data.appeals_item;
            }
            this.searchSubmitted = true;
            this.loadingState = 0;
          });
      }
    },
    appealSubmit() {
      if (this.loadingState === 0) {
        this.resetErrors();
        if (this.file && this.file.length) {
          this.submitFile();
        } else {
          this.submitForm();
        }
      }
    },
    submitFile() {
      this.loadingState = 1;
      this.$apollo
        .mutate({
          mutation: FILES_UPLOAD,
          variables: {
            files: this.file,
          },
        })
        .then(({ data }) => {
          if (data && data.FilesUpload && data.FilesUpload.length) {
            this.form.file.value = data.FilesUpload[0];
            this.submitForm();
          }
        })
        .catch(() => {
          this.loadingState = 0;
          this.$notify({
            title: "Ошибка",
            text: "Загрузка файла не удалась",
            duration: 5000,
            speed: 200,
            type: "error",
          });
        });
    },
    showCaptchaModal() {
      this.$store.state._modals.push({
        component: CaptchaModal,
        options: {
          callback: this.submitAppeal,
          set: (field, value) => {
            this.form[field].value = value;
          },
        },
      });
    },
    submitForm() {
      this.showCaptchaModal();
    },
    submitAppeal() {
      this.loadingState = 2;
      let variables = {};
      Object.keys(this.form).forEach((key) => {
        variables[key] = this.form[key].value;
      });
      variables.type = this.form.type.value ? this.form.type.value.id : undefined;
      this.$apollo
        .mutate({
          mutation: APPEAL_CREATE,
          variables: variables,
        })
        .then(({ data }) => {
          if (data && data.AppealCreate) {
            this.$notify({
              title: "Отправлено",
              text: data.AppealCreate.message,
              duration: 5000,
              speed: 200,
              type: "success",
            });
            this.resetForm();
            window.focus();
            window.scrollTo(0, 0);
            this.notify = `Ваше обращение отправлено! \nНа указанную Вами электронную почту отправлен номер обращения - ${data.AppealCreate.uuid}`;
            history.pushState({}, null, location.origin + location.pathname + "#search");
            this.searchPage = true;
          }
          this.loadingState = 0;
        })
        .catch(({ graphQLErrors }) => {
          this.loadingState = 0;
          this.parseGqlErrors(graphQLErrors);
        });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = JSON.parse(this.form[key].defaultValue);
      });
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = undefined;
      });
    },
  },
  metaInfo: {
    title: "Электронная форма обращения",
  },
  components: { IconComponent, FileInputComponent, TextareaComponent, SelectComponent, InputComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/notify.styl"
.search-form {
  display grid
  grid-gap 10px

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__row {
    display grid
    grid-template-columns 1fr auto
    grid-gap 10px
  }
}

.appeals-form {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  padding 30px
  display grid
  grid-gap 20px

  &--search {
    background none
    padding 0
    border-radius 0
    border 0
    grid-gap 30px
  }

  .input__container,
  .textarea__container,
  .select__field-container,
  .select__action,
  .file-input__container {
    background var(--gray)
  }

  &__footer {
    display grid
    grid-gap 20px
    grid-template-columns auto 1fr
    font-weight: 500;
    font-size: 0.875em
    line-height: 22px;
    color: var(--dark-light);
    align-items center
    +below(768px) {
      grid-template-columns auto
    }

    a {
      text-decoration underline
      color: var(--dark-light);
    }
  }
}

.form-row {
  display grid
  grid-gap 20px

  &--1 {
    grid-template-columns 1fr
  }

  &--2 {
    grid-template-columns repeat(2, 1fr)
    +below(560px) {
      grid-template-columns 1fr
    }
  }

  &--3 {
    grid-template-columns repeat(3, 1fr)
    +below(768px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(560px) {
      grid-template-columns 1fr
    }
  }
}

.appeals-results {
  display grid
  grid-gap 20px

  &__title {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
  }

  &__item {
    padding 20px
    background: var(--white);
    border-radius: 10px;
    display grid
    grid-gap 20px
  }

  &__item-header {
    display grid
    grid-template-columns 1fr auto
    grid-gap 15px
    padding-bottom 20px
    border-bottom 1px solid var(--gray-dark)
    align-items start
  }

  &__item-header-left {
    display grid
    grid-gap 5px
  }

  &__item-status {
    background: var(--dark-light);
    border: 1px solid var(--dark-light);
    border-radius: 5px;
    padding 5px 10px
    font-style: normal;
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--white);
  }

  &__item-top {
    display flex
    align-items center
    gap 24px
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    & > *:not(:last-child) {
      position relative

      &:after {
        content "•"
        font-weight 700
        absolute right -14px top bottom
        margin auto
        font-size: 1em;
        line-height: 14px;
        color: var(--dark-light);
      }
    }
  }

  &__item-title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__item-description {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__item-response {
    padding-left 20px
    display grid
    grid-gap 10px
  }

  &__item-response-title {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark-light);
  }

  &__item-response-description {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }
}
</style>
