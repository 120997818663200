<template>
  <div class="default-modal captcha-modal">
    <div class="captcha-modal__header default-modal__header">
      <span class="captcha-modal__title">Ввод капчи</span>
      <button
        type="button"
        class="default-modal__close"
        @click="$emit('close')"
        aria-label="Закрыть модальное окно"
      >
        <IconComponent category="default" name="close-fill" />
      </button>
    </div>
    <div class="captcha-modal__body default-modal__body">
      <div class="captcha-modal__img">
        <img :src="captcha_img" alt="" />
        <button @click="reloadCaptcha" class="btn" title="Загрузить другую капчу">
          <IconComponent category="default" name="restart-line" />
        </button>
      </div>
      <form @submit.prevent="submit" class="captcha-modal__form">
        <InputComponent v-model="form.captcha.value" placeholder="Введите решение" mask="####" />
        <button class="btn btn--md btn--main" aria-label="Отправить">Отправить</button>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CaptchaModal",
  components: {
    IconComponent,
    InputComponent,
  },
  props: {
    callback: Function,
    set: Function,
  },
  data() {
    return {
      loading: false,
      captcha_img: null,
      form: {
        captcha: {
          value: null,
          errors: [],
        },
        captcha_key: {
          value: null,
          errors: [],
        },
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    submit() {
      this.set("captcha", this.form.captcha.value);
      this.set("captcha_key", this.form.captcha_key.value);
      this.callback();
      this.$emit("close");
    },
    reloadCaptcha() {
      this.get();
    },
    get() {
      fetch(`${this.$store.state._env.MEDIA_ENDPOINT}/captcha/api/math`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        const data = await response.json();
        this.captcha_img = data.img;
        this.form.captcha_key.value = data.key;
      });
    },
  },
};
</script>

<style lang="stylus">
.captcha-modal {
  width 100%
  max-width 424px
  border-radius 16px
  padding: 33px 32px

  &__header {
    display flex
    justify-content space-between
    align-items center
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color #292929
  }

  &__body {
    align-items stretch
    gap 15px
  }

  &__form {
    display flex
    gap 16px
    width 100%

    .btn {
      flex-shrink 0
    }
  }

  &__img {
    position relative
    width 100%
    min-height 108px

    img {
      border-radius 15px
      vertical-align: middle;
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .btn {
      position absolute
      right 5px
      top 5px
      width 35px
      height 35px
      background #f7f7f7
      border-radius 5px
      padding 0

      &:hover {
        background var(--main)
        color var(--white)

        .icon svg path {
          fill var(--white)
        }
      }
    }
  }
}
</style>
